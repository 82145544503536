import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.22_@babel+core@7.26.0_@opentelemetry+api@1.8.0_@playwright+test@1.50.1_react-_829c497f9dc1f44cdd61a2d11f64153c/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.22_@babel+core@7.26.0_@opentelemetry+api@1.8.0_@playwright+test@1.50.1_react-_829c497f9dc1f44cdd61a2d11f64153c/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.22_@babel+core@7.26.0_@opentelemetry+api@1.8.0_@playwright+test@1.50.1_react-_829c497f9dc1f44cdd61a2d11f64153c/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.22_@babel+core@7.26.0_@opentelemetry+api@1.8.0_@playwright+test@1.50.1_react-_829c497f9dc1f44cdd61a2d11f64153c/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.22_@babel+core@7.26.0_@opentelemetry+api@1.8.0_@playwright+test@1.50.1_react-_829c497f9dc1f44cdd61a2d11f64153c/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.22_@babel+core@7.26.0_@opentelemetry+api@1.8.0_@playwright+test@1.50.1_react-_829c497f9dc1f44cdd61a2d11f64153c/node_modules/next/dist/client/components/render-from-template-context.js");
